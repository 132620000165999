import React from 'react';

import PdfPreview from '../../components/pdf-preview/pdf-preview';

import * as pdfData from '../../../static/schooling/dla_specjalistow.pdf';

class DlaSpecjalistowPage extends React.Component {
    render() {
        return (
            <PdfPreview pdfData={pdfData}></PdfPreview>
        )
    }
}

export default DlaSpecjalistowPage;